<template>
  <v-container fill-height fluid class="primarygrad">
    <v-row>
      <div style="height:200px"></div>
    </v-row>
    <v-row>
      <v-col xs="6" sm="6" md="8" aria-busy="">
        <v-row wrap>
          <v-col
            xs="10"
            offset-xs="1"
            sm="10"
            offset-sm="1"
            md="8"
            offset-md="5"
          >
            <v-card flat style="text-align: center !important">
              <v-card-text>
                <img
                  :src="logo"
                  alt="Godial Logo"
                  style="
                      width: 80px;
                      height: auto;
                      border-radius: 50px;
                    "
              /></v-card-text>
              <v-card-title
                primary-title
                class="justify-center"
                style="padding-top:0"
                >Welcome to GoDial</v-card-title
              >

              <v-card-title>
                <h4
                  style="font-size: 18px; font-weight: 500; letter-spacing: 0.5px"
                >
                  Set Password
                </h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="padding: 20px">
                <v-form>
                  <v-text-field
                    v-model="newPassword"
                    :type="visible ? 'text' : 'password'"
                    label="Enter New Password"
                    required
                    dense
                    outlined
                    :rules="passwordRules"
                    prepend-inner-icon="lock"
                    :append-icon="visible ? 'visibility' : 'visibility_off'"
                    @click:append="() => (visible = !visible)"
                    ref="resetPassword"
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="confirmPassword"
                    :type="visible ? 'text' : 'password'"
                    label="Confirm Password"
                    required
                    dense
                    outlined
                    :rules="passwordRules"
                    prepend-inner-icon="lock"
                    :append-icon="visible ? 'visibility' : 'visibility_off'"
                    @click:append="() => (visible = !visible)"
                    ref="resetPassword"
                  >
                  </v-text-field>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined text @click="resetPassword"
                  >Save</v-btn
                >
              </v-card-actions>
              <v-card-text>
                <v-spacer></v-spacer>

                <v-footer color="transparent" absolute>
                  <v-flex style="text-align: center !important">
                    <p
                      style="font-size: 12px; opacity: 0.6; margin-bottom: 0px"
                    >
                      © 2020 Godial Enterprise. All rights reserved
                    </p>
                  </v-flex>
                </v-footer>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <div style="height:400px"></div>
    </v-row>
    <v-dialog v-model="loaderDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "../js/firebase";

export default {
  data() {
    return {
      passwordRules: [
        (v) => !!v || "This field is required.",
        (v) =>
          /^(?!.* )(?=.*[a-zA-Z]).(?=.*[0-9]).{7,}$/.test(v) ||
          "Minimum eight characters, at least one letter and one number.",
      ],
      newPassword: "",
      username: "",
      confirmPassword: "",
      visible: false,
      loaderDialog: false,
      logo: require("../assets/logo.png"),
      smile: require("../assets/smile.gif"),
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT"]),
  },
  watch: {},
  created() {},
  methods: {
    refresh() {
      //check if logged in and goto home
      if (this.$store.getters.user) {
        this.$router.replace({ path: "/home" });
      }
    },

    doLogin() {
      const self = this;
      self.dialog = true;
      this.username = self.$route.query.username;
      if (this.username == "" || this.newPassword == "") {
        self.dialog = false;
        return self.$swal({
          type: "warning",
          text: "Username/Password is blank",
        });
      }

      this.$http
        .post(this.ENDPOINT + "/accounts/login", {
          username: this.username,
          password: this.newPassword,
        })
        .then((loginData) => {
          var accessToken = loginData.body.id;

          self.$http.headers.common.authorization = accessToken;

          self.$http
            .get(
              this.ENDPOINT +
                "/accounts/" +
                loginData.body.userId +
                "?filter[include]=teams",
              {
                headers: {
                  authorization: accessToken,
                },
              }
            )
            .then((user) => {
              if (user.body.role == "Agent") {
                alert("Only admins can access the dashboard");
                window.localStorage.clear();
                window.location.reload();
                return;
              }
              user = user.body;
              var token = loginData.body.id;
              user.token = token;

              self.$store.dispatch("setUser", user).then(() => {
                try {
                  fb.init(self.$store.state);
                } catch (e) {
                  console.log(e);
                }
              });
              self.dialog = false;
              self.refresh();
            });

          //Fetch the user
        })
        .catch((err) => {
          self.dialog = false;
          console.log(err);
          if (err.status == 0) {
            self.$swal({ type: "error", text: "Connection Error" });
          } else if (err.status == 401 && err.statusText == "Unauthorized") {
            self.$swal({ type: "error", text: "Invalid Username or Password" });
          } else {
            self.$swal({ type: "error", text: err.body.error.message });
          }
        });
    },
    resetPassword(e) {
      const self = this;
      if (this.confirmPassword != this.newPassword) {
        this.$swal({
          type: "error",
          text: "Passwords do not match!",
        });
        return;
      }
      console.log("this.newPassword.length ", this.newPassword.length);
      if (this.newPassword.length < 8 || !/\d/.test(this.newPassword)) {
        this.$swal({
          type: "error",
          text:
            "Password length must be at least 8 characters and contain a number!",
        });
        return;
      }
      if (this.newPassword == "") {
        this.$swal({
          type: "error",
          text: "Please provide a password",
        });
        return;
      }

      if (!/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/.test(self.newPassword)) {
        //  this.loader = false
        return this.$swal({
          type: "warning",
          text: "Symbols or special characters are not allowed",
        });
      }

      self.loaderDialog = true;

      self.$http
        .post(
          `${this.ENDPOINT}/accounts/reset-password?access_token=${self.$route.query.token}`,
          { newPassword: self.newPassword }
        )
        .then(
          (response) => {
            try {
              fb.log("new_password_add");
            } catch (e) {
              console.log("Error in FB log", e);
            }

            self.loaderDialog = false;
            self
              .$swal({
                type: "success",
                text: "Password Set Successfully",
              })
              .then((response) => {
                self.$swal({
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  showConfirmButton: false,
                  showCancelButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                  type: "success",
                  text: "Thank You for choosing Godial",
                  backdrop: `
    rgba(0,123,0,0.4)`,
                });

                this.doLogin();
              });
          },
          (error) => {
            self.loaderDialog = false;
            console.log(error);
            this.$swal({ type: "error", text: "Error while setting password" });
          }
        );
    },
  },
};
</script>

<style>
.v-card {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0), 0 1px 1px 0 rgba(0, 0, 0, 0),
    0 1px 3px 0 rgba(0, 0, 0, 0);
}
</style>
